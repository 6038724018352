import React from "react";

export default function Experience() {
  return (
    <section id="experience" className="lg:h-screen xl:h-screen dark:bg-primary bg-slate-100 relative">
      <div className="container mx-auto w-full lg:h-screen lg:px-16 flex flex-col justify-center md:p-4 lg:p-0 p-3">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h2 className="font-rubik font-bold capitalize  text-textPrimary lg:text-[40px] md:text-[30px] text-[20px] md:pt-3 tracking-[1px] z-10">
              Experiencias
            </h2>
            <div className="timeline mt-8">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {/* Experiencia 1 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                  <time className="text-lg text-sm text-gray-600 dark:text-slate-300 ">2023-2024</time>
                    <h2 className="text-xl font-semibold dark:text-slate-300">
                      NeoTelecom
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300 ">
                      <li className="font-bold mb-2">Helpdesk</li>

                      <li className="list-disc">
                        Responsable de proporcionar soporte y resolver problemas relacionados a los sistemas de la empresa.
                      </li>
                      <li className="list-disc">
                        Gestión de usuarios en los sistemas (creación, activación, asignación permisos, bloqueo y eliminación de cuentas de usuario según sea necesario).
                      </li>
                      <li className="list-disc">
                        Punto de contacto principal para recibir solicitudes de corrección de errores. 
                      </li>
                      <li className="list-disc">
                        Gestionar solicitudes de implementación dentro de los sistemas de la empresa.
                      </li>
                      <li className="list-disc">
                        Asignar Tareas a los Desarrolladores de los sistemas para la corrección o nuevas implementaciones dentro del sistema de la empresa, Utilizando el Metodo Ágil.
                      </li>
                      <li className="list-inside dark:text-slate-300 ">
                        <p className="font-bold mb-2">Otras funciones realizadas:</p>
                        <li className="list-disc ">
                          Área de TI: Mantenimiento en General, Ensamble de Computadoras, Soporte, etc.
                        </li>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 2 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  {/* <div className="timeline-yr">
                    <span className="text-lg text-sm text-gray-600">2021 - 2023</span>
                  </div> */}
                  <div className="timeline-info mt-4">
                  <span className="text-lg text-sm text-gray-600 dark:text-slate-300 ">2021 - 2023</span>
                    <h2 className="text-xl font-semibold dark:text-slate-300 ">
                      Fast Interner S.A, Apps & Tecnologia y Grupo Faster
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300 ">
                      <li className="font-bold mb-2">Desarrollador</li>
                      <li className="list-disc">Desarrollador Web</li>
                      <li className="list-disc">Despliegue de Páginas & Sistemas Web</li>
                      <li className="list-disc">Desarrollo Backend y frontend</li>
                      <li className="list-disc">Soporte TI</li>
                      <li className="list-disc">Técnico de redes</li>
                      <li className="list-disc">Acompañamiento a Instalaciones y Soporte técnico como tercerizado.</li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 3 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                 {/*  <span className="text-lg text-sm text-gray-600 dark:text-slate-300 ">2018 - 2020</span> */}
                    <h2 className="text-xl font-semibold dark:text-slate-300 ">
                      ITAIPÚ Binacional
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300 ">
                      <li className="font-bold mb-2">Pasante Curricular Universitario & Aprendiz</li>
                      <li className="list-disc ">Tareas Realizadas: Acompañamiento de los Mantenimientos Preventivos Periódicos de los Reguladores de Voltaje y los Reguladores de Velocidad de las Unidades Generadoras.</li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 4 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                  <span className="text-lg text-sm text-gray-600 dark:text-slate-300 ">2018</span>
                    <h2 className="text-xl font-semibold dark:text-slate-300 ">
                      Auxiliar de Electrica - Parana Ingenieria S.A
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300">
                      <li className="font-bold mb-2">Auxiliar de Electrica</li>
                      <li className="list-disc" >Tareas Realizadas: Acompañamiento rutinario para verificación del estado de las máquinas, ampliaciones de conexiones de las oficinas y conexión de transformadores del “Parque Industrial SanJuan”.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
