import React from "react";
import BackTopBtn from "./components/BackTopBtn";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Skills from "./components/Skills";
import Tecnology from "./components/Tecnology";
import Projects from "./components/Projects";
import ContextTheme from "./contextTheme/contextTheme";
import Education from "./components/Education";
import Experience from "./components/Experience";
import "./css/styles.css";

function App() {
  return (
    <ContextTheme>
      <Header />
      <main>
        <section id="hero">
          <Hero />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="Education">
          <Education />
        </section>
        <section id="Tecnology">
          <Tecnology />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="Experience">
          <Experience />
        </section>
      </main>
      <Footer />
      <BackTopBtn />
    </ContextTheme>
  );
}

export default App;
