import React, { useEffect, useState } from "react";
import { BsLinkedin, BsGithub, BsArrowDownCircle } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-scroll";
import ShapeDivider from "./ShapeDivider";

const navigation = { href: "skills" };

export default function Hero() {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <main
      id="home"
      className="dark:bg-primary bg-slate-100 h-screen relative flex justify-center items-center overflow-hidden -mt-20"
    >
      <ShapeDivider className="hidden md:block"/>
      <div className="horemb flex justify-center items-center z-10 flex-col px-4 sm:px-6 md:px-8 lg:px-16">
        <div
          className={`${
            animated ? "" : "translate-y-10 opacity-0"
          } transform transition duration-[2000ms] ease-in-out w-full sm:w-3/4 text-center`}
        >
          <h1 className="dark:text-textThird text-[#293855] text-[22px] sm:text-[32px] md:text-[40px] lg:text-[54px] xl:text-[58px] font-rubik tracking-[2px]">
            Hola👋, mi nombre es{" "}
            <br />
            <span className="text-textPrimary font-bold">
              Santiago Galeano Mendieta
            </span>
          </h1>
          <h2 className="dark:text-textThird text-[14px] sm:text-[18px] md:text-[24px] lg:text-[30px] font-rubik tracking-[2px] mb-6 font-semibold">
            Soy un Desarrollador 💻
          </h2>
          <p className="dark:text-blue-400 text-blue-600 text-[12px] sm:text-[14px] md:text-[16px] lg:text-[20px] font-rubik tracking-[1px] leading-[26px] xl:w-4/6 md:px-10 m-auto">
            Me gusta Desarrollar & Gestionar Sistemas Web.
          </p>
          <section className="flex justify-center space-x-8 mt-10">
            <div className="bg-black shadow-xl rounded-full w-[40px] sm:w-[45px] md:w-[50px] h-[40px] sm:h-[45px] md:h-[50px] flex justify-center items-center hover:opacity-75">
              <a
                href="https://linkedin.com/in/santiago-galeano-a8914416a"
                rel="noreferrer"
                target="_blank"
              >
                <BsLinkedin className="text-1xl sm:text-2xl md:text-3xl text-blue-500" />
              </a>
            </div>
            <div className="bg-black shadow-xl rounded-full w-[40px] sm:w-[45px] md:w-[50px] h-[40px] sm:h-[45px] md:h-[50px] flex justify-center items-center hover:opacity-75">
              <a
                href="https://wa.me/595982400207"
                rel="noreferrer"
                target="_blank"
              >
                <IoLogoWhatsapp className="text-1xl sm:text-2xl md:text-3xl text-white" />
              </a>
            </div>
            <div className="bg-black shadow-xl rounded-full w-[40px] sm:w-[45px] md:w-[50px] h-[40px] sm:h-[45px] md:h-[50px] flex justify-center items-center hover:opacity-75">
              <a
                href="https://github.com/GMSantiago-Dev"
                rel="noreferrer"
                target="_blank"
              >
                <BsGithub className="text-1xl sm:text-2xl md:text-3xl text-white" />
              </a>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
