import React from "react";
import { navigation } from "../helpers/iconSkills";

export default function Education() {
  return (
    <section
      className="lg:h-screen xl:h-screen dark:bg-primary bg-slate-100 relative"
      id="education"
    >
      <div className="container mx-auto w-full lg:h-screen lg:px-16 flex flex-col justify-center md:p-4 lg:p-0 p-3 education">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h2 className="font-rubik font-bold capitalize lg:mt-1 text-textPrimary lg:text-[40px] md:text-[30px] text-[20px] md:pt-3 tracking-[1px] z-10">
              Educación
            </h2>
            <div className="timeline mt-8">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {/* Experiencia 4 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                    <span className="text-lg text-sm text-gray-600 dark:text-slate-300">2019 - 2023</span>
                    <h2 className="text-xl font-semibold dark:text-slate-300">
                      Universidad Privada del Este Filial CDE
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300">
                      <li className="font-bold mb-2">Ingeniería en Informática</li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 3 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                    <span className="text-lg text-sm text-gray-600 dark:text-slate-300">2019 - 2022</span>
                    <h2 className="text-xl font-semibold dark:text-slate-300">
                      Universidad Privada del Este Filial CDE
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300">
                      <li className="font-bold mb-2">Lic. en Análisis de Sistemas</li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 2 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                    <span className="text-lg text-sm text-gray-600 dark:text-slate-300">2015 - 2017</span>
                    <h2 className="text-xl font-semibold dark:text-slate-300">
                      Universidad Católica “Nuestra Señora de la Asunción” Campus Alto Paraná
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300">
                      <li className="font-bold mb-2">Técnico Superior Electromecánico</li>
                    </ul>
                  </div>
                </div>

                {/* Experiencia 1 */}
                <div className="timeline-wrapper bg-white dark:bg-zinc-900 shadow-lg rounded-lg p-6">
                  <div className="timeline-info mt-4">
                    <time className="text-lg text-sm text-gray-600 dark:text-slate-300">2010-2012</time>
                    <h2 className="text-xl font-semibold dark:text-slate-300">
                      Colegio Cristiano Emanuel
                    </h2>
                    <ul className="text-sm text-gray-600 list-inside dark:text-slate-300">
                      <li className="font-bold mb-2">Bachiller Científico con énfasis en Ciencias Sociales y Tecnología</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
