import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { MdOutgoingMail } from "react-icons/md";
import NavMobile from "./NavMobile";
import Modal from "./Modal"; // Importa el componente Modal

export default function Header() {
  const [bgColor, setBgColor] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la apertura del modal

  useEffect(() => {
    setAnimated(true);

    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const scrollDirection = currentScrollTop > lastScrollTop ? "down" : "up";

      if (scrollDirection === "down" && currentScrollTop > 50) {
        setBgColor(true);
        setHideHeader(true);
      } else if (scrollDirection === "up") {
        setHideHeader(false);
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const darkModeActive = localStorage.getItem("darkMode") === "true";
    setIsDarkMode(darkModeActive);
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <header
      className={`fixed top-0 w-full z-20 transition-all duration-300 ${
        hideHeader ? "-translate-y-full" : "translate-y-0"
      } ${
        bgColor ? (isDarkMode ? "bg-[#0b0c10]" : "bg-slate-200") : "bg-white"
      }`}
    >
      <div className={isDarkMode ? "bg-[#0b0c10] dark:bg-[#000000] shadow-lg" : "bg-white dark:bg-[#000000] shadow-lg"}>
        <div className="container mx-auto flex justify-between items-center px-3 py-2 lg:px-16">
          <button
            onClick={handleOpenModal} // Abre el modal al hacer clic
            className={`${
              animated ? "" : "translate-y-10 opacity-0"
            } transform transition duration-[2000ms] ease-in-out flex bg-[#45a29e] items-center space-x-2 lg:px-2 p-1 rounded-[6px]`}
          >
            <MdOutgoingMail className="lg:text-[18px] text-[13px] text-slate-50" />
            <h1 className="lg:text-[16px] text-[12px] text-slate-50 font-rubik">
              {/* gmsantiago94@gmail.com */}
              Contáctame 
            </h1>
          </button>
          <div className="lg:block hidden">
            <Nav animated={animated} />
          </div>
          <div className="lg:hidden">
            <NavMobile animated={animated} />
          </div>
        </div>
      </div>

      {/* Renderiza el Modal */}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} />
    </header>
  );
}
