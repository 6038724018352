import React, { useState } from "react";
import axios from "axios";

export default function Modal({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  if (!isOpen) return null;

  const handleSendEmail = async () => {
    setIsSending(true);
    const emailData = {
      from: email,
      subject: `Mensaje de ${name}`,
      text: message,
    };

    try {
      const response = await axios.post(
        "https://backemail.fluxoweb.net/api/email/send-email",
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Email sent successfully:", response.data);
      alert("Email sent successfully");
    } catch (error) {
      //console.error("Error sending email:", error.response ? error.response.data : error.message);
      alert("Error sending email");
    } finally {
      setIsSending(false);
      onClose();
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div className="modal-container bg-white dark:bg-zinc-900 w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto mt-auto">
        {/* Modal content */}
        <div className="modal-content py-4 text-left px-6">
          {/* Modal header */}
          <div className="modal-header flex justify-between items-center pb-3">
            <h5 className="text-2xl font-bold dark:text-slate-300 ">Contactame</h5>
            <button
              className="text-gray-500 hover:text-gray-800 focus:outline-none"
              onClick={onClose}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Modal body */}
          <div className="modal-body">
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 dark:text-slate-300 text-sm font-bold mb-2">
                  Tu Nombre :
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-input w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 dark:text-slate-300 text-sm font-bold mb-2">
                  Tu Correo :
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-input w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-gray-700 dark:text-slate-300 text-sm font-bold mb-2">
                  Mensaje:
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="form-textarea w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                  rows="5"
                ></textarea>
              </div>
            </form>
          </div>

          {/* Modal footer */}
          <div className="modal-footer flex justify-end pt-4">
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none"
              onClick={onClose}
            >
              Cerrar
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 ml-2 rounded focus:outline-none"
              onClick={handleSendEmail}
              disabled={isSending}
            >
              {isSending ? "Enviando..." : "Enviar Mensaje"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
