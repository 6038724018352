import React from "react";
import { skills } from "../helpers/iconSkills";
import ShapeDividerSkills from "./ShapeDividerSkills";

export default function Skills() {
  return (
    <div
      id="skills"
      className="lg:h-screen xl:h-screen dark:bg-primary bg-slate-100 relative"
    >
      <ShapeDividerSkills className="hidden md:block"/>
      <div className="container mx-auto w-full lg:h-screen lg:px-16 flex flex-col justify-center md:p-4 lg:p-0 p-3 skill">
        <h1 className="skillmb font-rubik font-bold capitalize text-textPrimary lg:text-[40px] md:text-[30px] text-[20px] tracking-[1px] z-10 lg:pt-6 md:pt-20 pt-10 mt-52">
          ¿Quién soy?
        </h1>
        <p className="font-rubik dark:text-third text-blue-400 lg:text-[20px] md:text-[14px] text-[14px] mb-2 tracking-[1px] z-10 xl:w-2/4 lg:w-3/4">
          Soy un desarrollador en constante aprendizaje de nuevas tecnologías, tanto en el Frontend como en el Backend. Mi objetivo laboral reside en la posibilidad de encontrar una empresa estable en la que pueda crecer profesionalmente, con el compromiso de un fiel trabajador y miembro de un equipo.
        </p>
        <p className="font-rubik dark:text-third text-blue-400 lg:text-[20px] md:text-[16px] text-[12px] mb-4 tracking-[1px] z-10 xl:w-2/4 lg:w-3/4">
          Me considero una persona ordenada, honesta y de aprendizaje práctico.
        </p>
      </div>
    </div>
  );
}
